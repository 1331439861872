var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid map pt-6 px-0 pr-sm-2"},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',[_c('v-col',[_c('v-subheader',{staticClass:"pointer project-menu_subheader pl-0 pl-sm-2 pl-md-5",on:{"click":_vm.goHome}},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" "+_vm._s(!this.auth.currentUser ? "На главную" : "Список проектов")+" ")],1)],1)],1),(_vm.loadMapList)?_c('v-row',{staticClass:"pl-0 pl-sm-2 pl-md-5"},_vm._l((3),function(map,mapIndex){return _c('v-col',{key:mapIndex,attrs:{"xl":"3","md":"6","sm":"12","cols":"12"}},[_c('div',{staticClass:"map__item"},[_c('v-skeleton-loader',{attrs:{"type":"image","height":"100%","width":"100%"}})],1)])}),1):_c('v-row',{staticClass:"pl-0 pl-sm-2 pl-md-5"},_vm._l((_vm.mapList),function(map,mapIndex){return _c('v-col',{key:mapIndex,attrs:{"xl":"3","md":"6","sm":"12","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"map__item"},'div',attrs,false),on),[_c('a',{attrs:{"href":map.url.replace('/api', '') + '/display',"target":"_blank"}},[(!!map.social.preview_image_exists)?_c('div',{staticClass:"map__item-image map__item-no-image",style:({
                    backgroundImage: 'url(' + map.url + '/preview.png' + ')',
                  })}):_c('div',{staticClass:"map__item-no-image"}),_c('p',{staticClass:"white--text text-center",attrs:{"title":map.resource.display_name}},[_vm._v(" "+_vm._s(map.resource.display_name)+" ")])])])]}}],null,true)},[_c('span',{staticClass:"map__item-description",domProps:{"innerHTML":_vm._s(
              map.resource.description
                ? map.resource.description
                : 'описание отсутствует'
            )}})])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }