<template>
  <div class="container-fluid map pt-6 px-0 pr-sm-2">
    <v-container fluid fill-height>
      <v-row>
        <v-col>
          <v-subheader
            @click="goHome"
            class="pointer project-menu_subheader pl-0 pl-sm-2 pl-md-5"
          >
            <v-icon color="orange"> mdi-arrow-left </v-icon>
            {{ !this.auth.currentUser ? "На главную" : "Список проектов" }}
          </v-subheader>
        </v-col>
      </v-row>

      <v-row class="pl-0 pl-sm-2 pl-md-5" v-if="loadMapList">
        <v-col
          v-for="(map, mapIndex) in 3"
          :key="mapIndex"
          xl="3"
          md="6"
          sm="12"
          cols="12"
        >
          <div class="map__item">
            <v-skeleton-loader
              type="image"
              height="100%"
              width="100%"
            ></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>

      <v-row v-else class="pl-0 pl-sm-2 pl-md-5">
        <v-col
          v-for="(map, mapIndex) in mapList"
          :key="mapIndex"
          xl="3"
          md="6"
          sm="12"
          cols="12"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="map__item" v-bind="attrs" v-on="on">
                <a
                  :href="map.url.replace('/api', '') + '/display'"
                  target="_blank"
                >
                  <div
                    class="map__item-image map__item-no-image"
                    :style="{
                      backgroundImage: 'url(' + map.url + '/preview.png' + ')',
                    }"
                    v-if="!!map.social.preview_image_exists"
                  ></div>
                  <div class="map__item-no-image" v-else></div>
                  <p
                    class="white--text text-center"
                    :title="map.resource.display_name"
                  >
                    {{ map.resource.display_name }}
                  </p>
                </a>
              </div>
            </template>
            <span
              class="map__item-description"
              v-html="
                map.resource.description
                  ? map.resource.description
                  : 'описание отсутствует'
              "
            ></span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { getAuth } from "@firebase/auth";

export default {
  data() {
    return {
      mapList: [],
      loadMapList: true,
      auth: getAuth(),
    };
  },

  async mounted() {
    this.loadMapList = true;
    this.mapList = await this.getPublicMaps();
    this.loadMapList = false;
  },

  methods: {
    goHome() {
      if (!this.auth.currentUser) {
        this.$router.push({ name: "login" });
      } else {
        this.$router.push({ name: "home" });
      }
    },
    async getPublicMaps() {
      let PublicMapUrls = await this.$apollo.query({
        query: require("@/graphql/PublicMaps.gql"),
      });
      PublicMapUrls = PublicMapUrls.data.public_map.map((mapItem) => {
        return mapItem.url;
      });
      let arr = [];
      let urls = [];
      PublicMapUrls.forEach((mapUrl) => {
        let url = mapUrl.replace("/resource", "/api/resource");
        arr.push(axios.get(url));
        urls.push(url);
      });
      return this.settleMix(arr).then((results) => {
        let _maplist = [];
        results.forEach((response, responseIndex) => {
          if (response.state === "fullfilled") {
            // console.log(response)
            let resuorce = {
              // убираем обертку value.data и добавляем свойство url с урлом с которого запрашивали карту
              ...response.value.data,
              url: urls[responseIndex],
            };
            _maplist.push(resuorce);
          } else {
            console.log("fali", response);
          }
        });
        return _maplist;
      });
    },
  },
};
</script>

<style lang="scss">
.map__item-description {
  color: white;
  a {
    color: white;
  }
}
</style>